import React, { useEffect, useRef } from "react";
import ChangingText from "../../components/ChangingText/ChangingText";
import Showreel from "../../components/Showreel/Showreel";
import Title from "../../components/Text/Text";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./Hero.module.scss";
import { useMediaQuery } from "react-responsive";
import gsap from "gsap";
import { linesReveal } from "../../animations/text";
// import SplitText from "../../lib/SplitText";

const list = [
	"brands & digital experiences.",
	"apps & websites.",
	"strategy & design.",
];

const mobileList = ["brands.", "apps.", "websites.", "strategy.", "design."];

const Hero = React.forwardRef<HTMLVideoElement>((props, ref) => {
	const headingRef = useRef<HTMLHeadingElement>(null);
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-device-width: 769px)",
	});

	useEffect(() => {
		linesReveal(headingRef);
		// const splitHeading = new SplitText(headingRef.current, {
		// 	type: "lines",
		// });

		// // tslint:disable-next-line:no-unused-expression
		// new SplitText(headingRef.current, {
		// 	type: "lines",
		// 	linesClass: styles.lineWrap,
		// });

		// gsap.from(splitHeading.lines, {
		// 	delay: 0.3,
		// 	y: 50,
		// 	stagger: 0.3,
		// });
	}, []);

	return (
		<section className={styles.heroSection}>
			<Wrap>
				<Title
					type="headline"
					color="grey"
					style={styles.heroTitle}
					ref={headingRef}>
					Hello, we are outer. <br />
					We build{" "}
					<ChangingText
						color="white"
						list={isDesktopOrLaptop ? list : mobileList}
						className={styles.changingText}
					/>
				</Title>
			</Wrap>
			<Showreel ref={ref} />
		</section>
	);
});

export default Hero;
