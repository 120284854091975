import React, { Fragment } from "react";
import CustomScroll from "../components/CustomScroll/CustomScroll";
import Contacts from "../containers/Contacts/Contacts";
import Footer from "../containers/Footer/Footer";
import Header from "../containers/Header/Header";

const ContactsPage = () => (
	<main
		style={{
			backgroundColor: "#FAF9F5",
			minHeight: "100vh",
			display: "flex",
			flexDirection: "column",
		}}>
		<Header color="white" />
		<Contacts />
		<Footer showHeader={false} />
	</main>
);

export default ContactsPage;
