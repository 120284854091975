import React, { Fragment } from "react";
import About from "../containers/About/About";
import Clients from "../containers/Clients/Clients";
import Footer from "../containers/Footer/Footer";
import Header from "../containers/Header/Header";
import Services from "../containers/Services/Services";

const IndexPage = () => (
	<main style={{ backgroundColor: "#FAF9F5" }}>
		<Header color="white" />
		<About />
		<Services />
		<Clients />
		<Footer showHeader={true} />
	</main>
);

export default IndexPage;
