import { RefObject } from "react";
import styles from "./animations.module.scss";
import SplitText from "../lib/SplitText";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const linesRevealScroll = (ref: RefObject<HTMLHeadingElement>) => {
	const splitText = new SplitText(ref.current, {
		type: "lines",
	});

	// tslint:disable-next-line:no-unused-expression
	new SplitText(ref.current, {
		type: "lines",
		linesClass: styles.lineWrap,
	});

	gsap.from(splitText.lines, {
		scrollTrigger: {
			trigger: ref!.current!,
			toggleActions: "play none none none",
			start: "top 90%",
			refreshPriority: 0,
			// once: true,
			// markers: { startColor: "green", endColor: "red", fontSize: "12px" },
		},
		y: 45,
		autoAlpha: 0,
		delay: 0.25,
		stagger: 0.25,
		duration: 1,
		ease: "power4.out",
	});
};

export const linesReveal = (ref: RefObject<HTMLHeadingElement>) => {
	const splitText = new SplitText(ref.current, {
		type: "lines",
	});

	// tslint:disable-next-line:no-unused-expression
	new SplitText(ref.current, {
		type: "lines",
		linesClass: styles.lineWrap,
	});

	gsap.from(splitText.lines, {
		y: 45,
		autoAlpha: 0,
		delay: 0.25,
		stagger: 0.25,
		duration: 1,
		ease: "power4.out",
	});
};
