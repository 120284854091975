import cx from "classnames";
import React from "react";
import { navigate } from "gatsby";
import Button from "../../components/Button/Button";
import Title from "../../components/Text/Text";
import styles from "./FooterHeader.module.scss";

interface Props {
	show: boolean;
}

const FooterHeader = ({ show }: Props) => {
	return show ? (
		<div className={styles.footerHeader}>
			<Title type="headline" color="black" style={styles.title}>
				Let’s make something <br /> great <span>together</span>!
			</Title>
			<Button color="dark" to="/contact">
				Say hi!
			</Button>
		</div>
	) : null;
};

export default FooterHeader;
