export default {
	services: [
		{
			description:
				"Outer develops topnotch, award‑winning designs, and digital products. We're driven by user‑centered design that increases revenue. Outer offers digital services including UX/UI enhancement, custom web design, and development with high scalability and business solutions to create instant marketing impact.",
			key: "01",
			title: "Website Design & Development",
		},
		{
			description:
				"Outer's expert team of iOS, Android, and Cross-Platform mobile developers crafts highly polished applications. Our goals are simple: develop quality, high‑end software that guarantees results. Our team uses the best practices and most adequate technologies to speed up the app development and provide the best possible experience to your users.",
			key: "02",
			title: "Mobile Design & Development",
		},
		{
			description:
				"Outer specializes in creating and launching brands and rebranding. Our team is passionate about developing identity solutions that drive business into higher levels. Our job is to tell your brand story and create an authentic and memorable identity. We will define your visual identity, implement the design, and prepare eye‑catching marketing materials.",
			key: "03",
			title: "Identity Design & Branding",
		},
		{
			description:
				"Our talented team prides in having experts in the field who create the best quality sophisticated illustrated and animated content. Our goal is to help brands achieve new heights thanks to compelling storytelling and distinct design while keeping everything to the smallest detail in line with your overall vision and feel.",
			key: "04",
			title: "Illustration & Animation",
		},
	],
};
