import React, { useEffect, useRef } from "react";
// import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import styles from "./CustomScroll.module.scss";
import Scrollbar from "../../lib/SmoothScrollbar";

interface Props {
	children: React.ReactNode;
}

const documentGlobal = typeof document !== "undefined" ? document : undefined;

export let bodyScrollBar;

const CustomScroll = ({ children }: Props) => {
	gsap.registerPlugin(ScrollTrigger);
	const scrollbarRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		const { scrollbar } = scrollbarRef.current;

		bodyScrollBar = scrollbar;

		const cursor = documentGlobal!.querySelector(
			"#custom-cursor"
		) as HTMLSpanElement;

		bodyScrollBar.addListener(({ offset }) => {
			if (cursor !== null) {
				gsap.to(cursor, {
					top: offset.y + "px",
					duration: 0.001,
				});
			}
		});

		// bodyScrollBar.scrollTo(0, 0);
	}, [scrollbarRef.current]);

	return (
		<Scrollbar damping={0.08} ref={scrollbarRef}>
			<div>
				<div className={styles.scrollContainer}>{children}</div>
			</div>
		</Scrollbar>
	);
};

export default CustomScroll;
