import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import Input from "../Input/Input";
import TextArea from "../TextArea/TextArea";
import styles from "./Form.module.scss";
import cx from "classnames";
import { emailRegexp } from "../../utilities/regexp";

interface Props {
	onSuccess: (success: boolean) => void;
}

interface Inputs {
	name: string;
	email: string;
	message: string;
	// file: File;
}

const Form = ({ onSuccess }: Props) => {
	const { register, handleSubmit, watch, errors } = useForm();
	const formRef = useRef<HTMLFormElement>(null);

	const onSubmit = data => {
		const form = formRef.current!;
		const formData = new FormData(formRef.current!);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) {
				return;
			}
			if (xhr.status === 200) {
				form.reset();
				onSuccess(true);
			} else {
				console.log("ERROR");
				// this.setState({ status: "ERROR" });
			}
		};
		xhr.send(formData);
	};

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit(onSubmit)}
			action="https://formspree.io/mqkygkqy"
			method="POST"
			encType="multipart/form-data"
			ref={formRef}>
			<div className={styles.formItem}>
				<Input
					type="text"
					placeholder="Name"
					style={cx({
						[styles.errorInput]: errors.name,
					})}
					name="name"
					ref={register({ required: "Name is required" })}
				/>
				{errors.name && (
					<span className={styles.errorMessage}>{errors.name.message}</span>
				)}
			</div>

			<div className={styles.formItem}>
				<Input
					type="email"
					placeholder="E-mail"
					style={cx({
						[styles.errorInput]: errors._replyto,
					})}
					name="_replyto"
					ref={register({
						pattern: {
							message: "Entered value does not match email format",
							value: emailRegexp,
						},
						required: "Email is required",
					})}
				/>
				{errors._replyto && (
					<span className={styles.errorMessage}>{errors._replyto.message}</span>
				)}
			</div>

			<div className={styles.formItem}>
				<TextArea
					placeholder="Message"
					style={cx({
						[styles.errorInput]: errors.message,
					})}
					name="message"
					ref={register({ required: "Message is required" })}
				/>
				{errors.message && (
					<span className={styles.errorMessage}>{errors.message.message}</span>
				)}
			</div>

			{/* <Input
				type="file"
				placeholder="Attach file"
				style={styles.input}
				name="upload"
				ref={register}
			/> */}
			<Button color="dark">Send</Button>
		</form>
	);
};

export default Form;
