import React, { useRef, useEffect } from "react";
import Header from "../../containers/Header/Header";
import ProjectHero from "../../containers/ProjectHero/ProjectHero";
import ProjectHeader from "../../containers/ProjectHeader/ProjectHeader";
import TransitionLink from "gatsby-plugin-transition-link";
import { bodyScrollBar } from "../../components/CustomScroll/CustomScroll";
import { useStaticQuery, graphql } from "gatsby";
import {
	projectsEntryAnimation,
	projectExitAnimation,
} from "../../animations/other";

interface Props {
	offset: {
		x: number;
		y: number;
	};
}

const Totec = () => {
	const headerRef = useRef<HTMLElement>(null);
	const heroRef = useRef<HTMLElement>(null);

	let scrollPosition = 0;

	useEffect(() => {
		const scrollMonitor = ({ offset }: Props) => {
			if (offset.y !== 0) {
				scrollPosition = offset.y;
			}
		};

		if (typeof document !== undefined && bodyScrollBar) {
			bodyScrollBar.addListener(scrollMonitor);
		}

		return () => {
			if (typeof document !== undefined && bodyScrollBar) {
				bodyScrollBar.removeListener(scrollMonitor);
			}
		};
	});

	const res = useStaticQuery(graphql`
		query TotecQuery {
			markdownRemark(frontmatter: { templateKey: { eq: "totec" } }) {
				frontmatter {
					title
					projectType
					description
					links {
						title
						link
					}
					delivered
					images {
						order
						size
						image {
							childImageSharp {
								fluid(maxWidth: 1440) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
					videos {
						order
						size
						video {
							publicURL
						}
					}
					imageCover {
						publicURL
					}

					nextProject {
						title
						projectType
						link
						cover {
							type
							source {
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const data = res.markdownRemark.frontmatter;

	return (
		<main style={{ backgroundColor: "#000000" }}>
			<Header color="black" />
			<ProjectHero data={data} ref={heroRef} />
			<TransitionLink
				to={data.nextProject.link}
				exit={{
					trigger: ({ node, e, exit, entry }) =>
						projectExitAnimation({
							node,
							e,
							exit,
							entry,
							scrollPosition,
							headerRef,
							heroRef,
						}),
					length: 2,
				}}
				entry={{
					trigger: projectsEntryAnimation,
					delay: 2,
				}}
				style={{ textDecoration: "none", color: "inherit", display: "block" }}>
				<ProjectHeader
					title={data.nextProject.title}
					projectType={data.nextProject.projectType}
					source={data.nextProject.cover.source}
					ref={headerRef}
				/>
			</TransitionLink>
		</main>
	);
};

export default Totec;
