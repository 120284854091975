import cx from "classnames";
import React from "react";
import styles from "./Wrap.module.scss";

interface Props {
	children: JSX.Element[] | JSX.Element;
	style?: string;
}

const Wrap = ({ children, style }: Props) => (
	<div className={cx(styles.wrap, style)}>{children}</div>
);

export default Wrap;
