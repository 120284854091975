import React from "react";
import Slider from "react-slick";
import ClientLogo from "../../components/ClientLogo/ClientLogo";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./Clients.module.scss";
import ClientLogos from "./ClientsLogos";

const Clients = () => {
	const options = {
		accessibility: false,
		arrows: false,
		autoplay: true,
		draggable: false,
		infinite: true,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				},
			},
		],
		pauseOnHover: false,
		touchMove: false,
		slidesToScroll: 1,
		slidesToShow: 6,
		swipe: false,
	};
	return (
		<section className={styles.clients}>
			<Wrap style={styles.clientsWrap}>
				<Slider {...options}>
					{ClientLogos.map(logo => (
						<ClientLogo image={logo.image} key={logo.key} />
					))}
				</Slider>
			</Wrap>
		</section>
	);
};

export default Clients;
