// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-ecodenta-tsx": () => import("./../src/pages/work/ecodenta.tsx" /* webpackChunkName: "component---src-pages-work-ecodenta-tsx" */),
  "component---src-pages-work-hello-world-tsx": () => import("./../src/pages/work/hello-world.tsx" /* webpackChunkName: "component---src-pages-work-hello-world-tsx" */),
  "component---src-pages-work-logos-tsx": () => import("./../src/pages/work/logos.tsx" /* webpackChunkName: "component---src-pages-work-logos-tsx" */),
  "component---src-pages-work-mone-tsx": () => import("./../src/pages/work/mone.tsx" /* webpackChunkName: "component---src-pages-work-mone-tsx" */),
  "component---src-pages-work-naturalist-tsx": () => import("./../src/pages/work/naturalist.tsx" /* webpackChunkName: "component---src-pages-work-naturalist-tsx" */),
  "component---src-pages-work-pocket-place-tsx": () => import("./../src/pages/work/pocket-place.tsx" /* webpackChunkName: "component---src-pages-work-pocket-place-tsx" */),
  "component---src-pages-work-totec-tsx": () => import("./../src/pages/work/totec.tsx" /* webpackChunkName: "component---src-pages-work-totec-tsx" */),
  "component---src-pages-work-tsx": () => import("./../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

