import cx from "classnames";
import React, { MutableRefObject } from "react";
import styles from "./Text.module.scss";

interface Props {
	type: "headline" | "subheading" | "paragraph";
	color: "grey" | "white" | "black";
	children: React.ReactNode;
	style?: string;
	onClick?: () => void | undefined;
}

const Text = React.forwardRef(
	({ children, style, type, color, onClick }: Props, ref) => (
		<h2
			className={cx(style, styles[type], styles[color])}
			onClick={onClick}
			ref={ref as MutableRefObject<HTMLHeadingElement>}>
			{children}
		</h2>
	)
);

// const Title = React.forwardRef(
// 	({ children, style, type = "heading", color = "grey" }: Props, ref) => (
// 		<h2 ref={ref} className={cx(style, styles[type], styles[color])}>
// 			{children}
// 		</h2>
// 	)
// );

export default Text;
