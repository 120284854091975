import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef, Fragment, RefObject } from "react";
import video from "../../assets/videos/showreel.mp4";
import mobileVideo from "../../assets/videos/showreel-mobile.mp4";
import styles from "./Showreel.module.scss";
import { Mobile, Tablet } from "../../utilities/responsive";

const Showreel = React.forwardRef<HTMLVideoElement>((props, ref) => {
	const refreshScroll = () => {
		ScrollTrigger.refresh();
	};

	useEffect(() => {
		ScrollTrigger.matchMedia({
			"(max-width: 480px)": () => {
				gsap.to(ref.current, {
					scrollTrigger: {
						trigger: ref.current,
						toggleActions: "play reverse play reverse",
						start: "top 35%",
						end: "bottom 75%",
						// markers: {
						// 	startColor: "green",
						// 	endColor: "red",
						// 	fontSize: "12px",
						// },
						scrub: 0.25,
						refreshPriority: 0,
						onLeave: () => refreshScroll(),
					},
					width: "100%",
					ease: "power2.out",
				});
			},
			"(min-width: 481px) and (max-width: 768px)": () => {
				gsap.to(ref.current, {
					scrollTrigger: {
						trigger: ref.current,
						toggleActions: "play reverse play reverse",
						start: "top 35%",
						end: "bottom 45%",
						// markers: {
						// 	startColor: "green",
						// 	endColor: "red",
						// 	fontSize: "12px",
						// },
						scrub: 0.25,
						refreshPriority: 0,
						onLeave: () => refreshScroll(),
					},
					width: "100%",
					ease: "power2.out",
				});
			},
			"(min-width: 769px)": () => {
				gsap.to(ref.current, {
					scrollTrigger: {
						trigger: ref.current,
						toggleActions: "play reverse play reverse",
						start: "top 400",
						end: "bottom+=100 70%",
						// markers: {
						// 	startColor: "green",
						// 	endColor: "red",
						// 	fontSize: "12px",
						// },
						scrub: 0.25,
						refreshPriority: 0,
						onLeave: () => refreshScroll(),
					},
					width: "100%",
					ease: "power2.out",
				});
			},
		});
		ref!.current.addEventListener(
			"loadeddata",
			() => {
				refreshScroll();
			},
			false
		);
	}, [ref]);

	return (
		<Fragment>
			<Tablet>
				<video
					playsInline={true}
					autoPlay={true}
					className={styles.video}
					loop={true}
					muted={true}
					ref={ref as RefObject<HTMLVideoElement>}>
					<source src={video} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</Tablet>
			<Mobile>
				<video
					autoPlay={true}
					playsInline={true}
					className={styles.video}
					loop={true}
					muted={true}
					ref={ref as RefObject<HTMLVideoElement>}>
					<source src={mobileVideo} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</Mobile>
		</Fragment>
	);
});

export default Showreel;
