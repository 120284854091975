import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import Text from "../../components/Text/Text";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./About.module.scss";
import SplitText from "../../lib/SplitText";

const dataText = [
	"Hello,",
	"We are a passionate team who can take on any challenge in the sphere of websites and apps.",
	"We love everything we do but what really makes us shine is years of experience and a deep knowledge of internal business processes that empower us to develop products which are exactly as they should be.",
	"We never take the easy way and keep up with the times. We believe in the power of a strong business relationship and years-long collaboration. Success takes joint effort.",
	"We are outer.",
];

const About = () => {
	const sectionRef = useRef<HTMLElement>(null);
	const refs: HTMLHeadingElement[] = [];

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		let tween: gsap.core.Tween;

		tween = gsap.to(["main", "header"], {
			scrollTrigger: {
				trigger: sectionRef!.current!,
				toggleActions: "play none play reverse",
				start: "bottom center",
				refreshPriority: 3,
			},
			backgroundColor: "#000000",
			ease: "none",
		});

		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: sectionRef!.current!,
				endTrigger: "footer",
				toggleActions: "play none none none",
				start: "top center",
				end: "bottom top",
				refreshPriority: 2,
				once: true,
			},
		});

		refs.forEach(ref => {
			const splitText = new SplitText(ref, {
				type: "lines",
			});

			// tslint:disable-next-line:no-unused-expression
			new SplitText(ref, {
				type: "lines",
				linesClass: styles.lineWrap,
			});

			tl.from(
				splitText.lines,
				{
					y: 45,
					autoAlpha: 0,
					// delay: -0.25,
					stagger: 0.25,
					duration: 1,
					ease: "power4.out",
				},
				"-=0.9"
			);
		});

		// return () => {
		// 	tween.pause(0).kill();
		// 	ScrollTrigger.getAll()[0].kill();
		// };
	}, [sectionRef]);

	return (
		<section className={styles.aboutSection} ref={sectionRef}>
			<Wrap>
				{dataText.map((text, i) => (
					<Text
						color="white"
						type="headline"
						style={styles.paragraph}
						key={text}
						ref={(node: HTMLHeadingElement) => (refs[i] = node)}>
						{text}
					</Text>
				))}
				{/* <Text color="white" type="headline" style={styles.paragraph}>
					Hello,
				</Text>
				<Text color="white" type="headline" style={styles.paragraph}>
					We are a passionate team who can take on any challenge in the sphere
					of websites and apps.
				</Text>
				<Text color="white" type="headline" style={styles.paragraph}>
					We love everything we do but what really makes us shine is years of
					experience and a deep knowledge of internal business processes that
					empower us to develop products which are exactly as they should be.
				</Text>
				<Text color="white" type="headline" style={styles.paragraph}>
					We never take the easy way and keep up with the times. We believe in
					the power of a strong business relationship and years-long
					collaboration. Success takes joint effort.
				</Text>
				<Text color="white" type="headline" style={styles.paragraph}> */}
				{/* We are outer.
				</Text> */}
			</Wrap>
		</section>
	);
};

export default About;
