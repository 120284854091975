interface Params {
	list: string[];
	listTl: gsap.core.Timeline;
	refs: HTMLHeadingElement[];
}

export const addToTimeline = ({ list, listTl, refs }: Params) => {
	list.map((text, i) => {
		const lastIndex = list.length - 1;
		switch (i) {
			case 0:
				/* prettier-ignore */
				listTl.to(refs[i], {
					delay: 1.5,
					duration: 0.4,
					ease: "Power1.easeOut",
					y: "-52px",
					autoAlpha: 0
				}).set(refs[1], {
					display: "block",
					y: "35px",
				}, "<" ).to(refs[1], {
					duration: 0.4,
					ease: "Power1.easeOut",
					y: 0,
					autoAlpha: 1
				}, "<" );
				break;

			case lastIndex:
				/* prettier-ignore */
				listTl.to(refs[i], {
					delay: 1.5,
					duration: 0.4,
					ease: "Power1.easeOut",
					autoAlpha: 0,
					y: "-52px",
				}, ">" ).set(refs[0], {
					display: "block",
					y: "35px",
				}, "<-0.1" ).to(refs[0], {
					ease: "Power1.easeOut",
					duration: 0.4,
					y: 0,
					autoAlpha: 1
				}, "<0.1" );
				break;

			default:
				/* prettier-ignore */
				listTl.to(refs[i], {
					delay: 1.5,
					duration: 0.4,
					ease: "Power1.easeOut",
					autoAlpha: 0,
					y: "-52px",
				}, ">" ).set(refs[i + 1], {
					display: "block",
					y: "35px",
				}, "<-0.1" ).to(refs[i + 1], {
					ease: "Power1.easeOut",
					duration: 0.4,
					y: 0,
					autoAlpha: 1
				}, "<0.1" );
				break;
		}
	});
};
