import cx from "classnames";
import { gsap, Power2, TimelineLite, TimelineMax, TweenMax } from "gsap";
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from "react";
import styles from "./Burger.module.scss";
import { bodyScrollBar } from "../CustomScroll/CustomScroll";

interface Props {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

const Burger = ({ open, setOpen }: Props) => {
	let top = useRef(null);
	let bottom = useRef(null);

	const [burgerTl] = useState(() => new TimelineMax({ paused: true }));

	useEffect(() => {
		burgerTl
			.to(
				top,
				{
					duration: 0.3,
					y: 4.5,
					ease: Power2.easeInOut,
				},
				0.3
			)
			.to(
				bottom,
				{
					duration: 0.3,
					y: -4.5,
					ease: Power2.easeInOut,
				},
				0.3
			)
			.to(
				top,
				{
					duration: 0.5,
					rotation: 45,
					delay: 0.32,
					ease: Power2.easeInOut,
				},
				0.3
			)
			.to(
				bottom,
				{
					duration: 0.5,
					rotation: -45,
					delay: 0.32,
					ease: Power2.easeInOut,
				},
				0.3
			);
	}, []);

	useEffect(() => {
		if (open) {
			burgerTl.play();
			if (typeof document !== undefined && bodyScrollBar) {
				bodyScrollBar.updatePluginOptions("filterEvent", {
					blacklist: [/wheel/, /touch/],
				});
			}
		} else {
			burgerTl.reverse();
			if (typeof document !== undefined && bodyScrollBar) {
				bodyScrollBar.updatePluginOptions("filterEvent", {
					blacklist: [],
				});
			}
		}
		// open === true ? burgerTl.play() : burgerTl.reverse();
	}, [open]);

	return (
		<div
			onClick={() => setOpen(!open)}
			className={cx(styles.burger, open ? styles.open : null)}>
			<span ref={el => (top = el)} />
			<span ref={el => (bottom = el)} />
		</div>
	);
};

export default Burger;
