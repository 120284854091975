import React from "react";
import PropTypes from "prop-types";
import SmoothScrollbar, { ScrollbarPlugin } from "smooth-scrollbar";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

class FilterEventPlugin extends ScrollbarPlugin {
	static pluginName = "filterEvent";

	static defaultOptions = {
		blacklist: [],
	};

	transformDelta(delta, fromEvent) {
		if (this.shouldBlockEvent(fromEvent)) {
			return { x: 0, y: 0 };
		}

		return delta;
	}

	shouldBlockEvent(fromEvent) {
		return this.options.blacklist.some(rule => fromEvent.type.match(rule));
	}
}

SmoothScrollbar.use(FilterEventPlugin);

export default class Scrollbar extends React.Component {
	static propTypes = {
		damping: PropTypes.number,
		thumbMinSize: PropTypes.number,
		syncCallbacks: PropTypes.bool,
		renderByPixels: PropTypes.bool,
		alwaysShowTracks: PropTypes.bool,
		continuousScrolling: PropTypes.bool,
		wheelEventTarget: PropTypes.element,
		plugins: PropTypes.object,
		onScroll: PropTypes.func,
		children: PropTypes.node,
	};

	static childContextTypes = {
		getScrollbar: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.callbacks = [];
	}

	getChildContext() {
		return {
			getScrollbar: cb => {
				if (typeof cb !== "function") return;

				if (this.scrollbar) setTimeout(() => cb(this.scrollbar));
				else this.callbacks.push(cb);
			},
		};
	}

	componentDidMount() {
		gsap.registerPlugin(ScrollTrigger);

		const scrollbar = SmoothScrollbar.init(this.$container, this.props);
		this.scrollbar = scrollbar;

		ScrollTrigger.scrollerProxy("body", {
			scrollTop(value) {
				if (arguments.length) {
					scrollbar.scrollTop = value;
				}

				return scrollbar.scrollTop;
			},
		});

		this.scrollbar.setPosition(0, 0);
		this.scrollbar.track.xAxis.element.remove();

		this.callbacks.forEach(cb => {
			requestAnimationFrame(() => cb(this.scrollbar));
		});

		scrollbar.addListener(this.handleScroll.bind(this));
	}

	componentWillUnmount() {
		if (this.scrollbar) {
			this.scrollbar.destroy();
		}
	}

	componentWillReceiveProps(nextProps) {
		Object.keys(nextProps).forEach(key => {
			if (!key in this.scrollbar.options) {
				return;
			}

			if (key === "plugins") {
				Object.keys(nextProps.plugins).forEach(pluginName => {
					this.scrollbar.updatePluginOptions(
						pluginName,
						nextProps.plugins[pluginName]
					);
				});
			} else {
				this.scrollbar.options[key] = nextProps[key];
			}
		});
	}

	componentDidUpdate() {
		this.scrollbar && this.scrollbar.update();
	}

	handleScroll(status) {
		if (this.props.onScroll) {
			this.props.onScroll(status, this.scrollbar);
		}
	}

	render() {
		const {
			damping,
			thumbMinSize,
			syncCallbacks,
			renderByPixels,
			alwaysShowTracks,
			continuousScrolling,
			wheelEventTarget,
			plugins,

			onScroll,
			children,
			...others
		} = this.props;

		return (
			<section
				data-scrollbar
				ref={element => (this.$container = element)}
				{...others}>
				<div>{children}</div>
			</section>
		);
	}
}
