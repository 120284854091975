import cx from "classnames";
import React, { useRef, useEffect, RefObject } from "react";
import CustomLink from "../../components/CustomLink/CustomLink";
import ParallaxImage from "../../components/ParallaxImage/ParallaxImage";
import Text from "../../components/Text/Text";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./ProjectHero.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Img, { FluidObject } from "gatsby-image";

interface Props {
	data: {
		title: string;
		projectType: string;
		description: string[];
		links: Array<{
			title: string;
			link: string;
		}>;
		delivered: string[];
		images: Array<{
			order: number;
			size: "full" | "half";
			image: {
				childImageSharp: {
					fluid: FluidObject | FluidObject[] | undefined;
				};
			};
		}>;
		videos: Array<{
			order: number;
			size: "full" | "half";
			video: {
				publicURL: string;
			};
		}>;
		videoCover?: {
			publicURL: string;
		};
		imageCover?: {
			publicURL: string;
		};
	};
}

const ProjectHero = React.forwardRef(({ data }: Props, ref) => {
	const coverRef = useRef<HTMLImageElement | HTMLVideoElement | Img>(null);

	useEffect(() => {
		// let tweenImage: gsap.core.Tween;
		let tweenBg: gsap.core.Tween;

		setTimeout(() => {
			if (null !== coverRef.current) {
				ScrollTrigger.matchMedia({
					"(max-width: 480px)": () => {
						// if (null !== coverRef.current) {
						gsap.to(coverRef.current, {
							scrollTrigger: {
								trigger: coverRef.current,
								toggleActions: "play reverse play reverse",
								start: "top 20%",
								end: "bottom 40%",
								// markers: {
								// 	startColor: "green",
								// 	endColor: "red",
								// 	fontSize: "12px",
								// },
								scrub: true,
							},
							width: "100%",
							ease: "power2.out",
							// duration: 0.35,
						});
						// }
					},
					"(max-width: 1399px)": () => {
						gsap.to(coverRef.current, {
							scrollTrigger: {
								trigger: coverRef.current,
								toggleActions: "play reverse play reverse",
								start: "top 20%",
								end: "bottom 42%",
								scrub: true,
							},
							width: "100%",
							ease: "power2.out",
						});
					},
					"(min-width: 1440px)": () => {
						gsap.to(coverRef.current, {
							scrollTrigger: {
								trigger: coverRef.current,
								toggleActions: "play reverse play reverse",
								start: "top 40%",
								end: "bottom 72%",
								scrub: true,
							},
							width: "100%",
							ease: "power2.out",
						});
					},
				});

				// tweenImage = gsap.to(coverRef.current, {
				// 	scrollTrigger: {
				// 		trigger: coverRef.current,
				// 		toggleActions: "play reverse play reverse",
				// 		start: "top center",
				// 		end: "bottom 90%",
				// 		// markers: { startColor: "green", endColor: "red", fontSize: "12px" },
				// 		scrub: true,
				// 		refreshPriority: 0,
				// 	},
				// 	width: "100%",
				// 	ease: "none",
				// });

				tweenBg = gsap.to(["main", "header"], {
					scrollTrigger: {
						trigger: coverRef.current,
						start: "top top",
						toggleActions: "play none none reset",
						toggleClass: "is-light-mode",
						// markers: {
						// 	startColor: "green",
						// 	endColor: "red",
						// 	fontSize: "12px",
						// },

						refreshPriority: 0,
					},
					backgroundColor: "#FAF9F5",
					// ease: "power2.out",
					// duration: 0,
				});
			}
		}, 500);
		return () => {
			// tweenImage.pause(0).kill();
			tweenBg.pause(0).kill();
			ScrollTrigger.getAll()[0].kill();
			// ScrollTrigger.getAll()[1].kill();
		};
	}, [coverRef]);

	return (
		<section
			className={styles.projectHero}
			ref={ref as React.MutableRefObject<HTMLElement>}>
			<Wrap>
				<div className={styles.textWrap}>
					<Text color="white" type="headline">
						{data.title}
					</Text>
					<Text color="grey" type="headline">
						{data.projectType}
					</Text>
				</div>
			</Wrap>

			{data.imageCover ? (
				<img
					src={data.imageCover.publicURL}
					className={styles.heroCover}
					ref={coverRef as RefObject<HTMLImageElement>}
				/>
			) : (
				// <Img
				// 	fluid={data.imageCover.childImageSharp.fluid}
				// 	className={styles.heroCover}
				// 	ref={coverRef as RefObject<Img>}
				// />
				<video
					playsInline={true}
					autoPlay={true}
					className={styles.heroCover}
					loop={true}
					muted={true}
					ref={coverRef as RefObject<HTMLVideoElement>}>
					<source src={data.videoCover?.publicURL} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			)}

			<Wrap>
				<div className={styles.projectInformation}>
					<div
						className={cx(styles.projectInformationBlock, styles.description)}>
						<Text
							type="subheading"
							color="grey"
							style={styles.projectInformationSubheading}>
							Description
						</Text>
						{data.description.map(item => (
							<Text
								type="subheading"
								color="white"
								style={cx(styles.paragraph, styles.descriptionParagraph)}
								key={item}>
								{item}
							</Text>
						))}
					</div>
					<div className={styles.projectInformationBlock}>
						<Text
							type="subheading"
							color="grey"
							style={styles.projectInformationSubheading}>
							Links
						</Text>
						<div className={styles.linkBlock}>
							{data.links.map(link => (
								<CustomLink
									to={link.link}
									color="white"
									style={cx(styles.paragraph)}
									type="link"
									key={link.title}>
									{link.title}
								</CustomLink>
							))}
						</div>
					</div>
					<div className={styles.projectInformationBlock}>
						<Text
							type="subheading"
							color="grey"
							style={styles.projectInformationSubheading}>
							Delivered
						</Text>
						{data.delivered.map(delivery => (
							<Text
								type="subheading"
								color="white"
								style={cx(styles.paragraph)}
								key={delivery}>
								{delivery}
							</Text>
						))}
					</div>
				</div>
				<div className={styles.gallery}>
					{data.images.map(item =>
						item.size === "full" ? (
							<div
								className={styles.galleryFullRow}
								style={{ order: item.order }}>
								<Img fluid={item.image.childImageSharp.fluid} />
							</div>
						) : (
							<div
								className={styles.galleryHalfRow}
								style={{ order: item.order }}>
								<Img fluid={item.image.childImageSharp.fluid} />
							</div>
						)
					)}

					{data.videos.map(video =>
						video.size === "full" ? (
							<div
								className={styles.galleryFullRow}
								style={{ order: video.order }}>
								<video
									playsInline={true}
									autoPlay={true}
									loop={true}
									muted={true}>
									<source src={video.video.publicURL} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						) : (
							<div
								className={styles.galleryHalfRow}
								style={{ order: video.order }}>
								<video
									playsInline={true}
									autoPlay={true}
									loop={true}
									muted={true}>
									<source src={video.video.publicURL} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						)
					)}
				</div>
			</Wrap>
		</section>
	);
});

export default ProjectHero;
