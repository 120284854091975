import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import Button from "../../components/Button/Button";
import Project from "../../components/Project/Project";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./Projects.module.scss";

const Projects = React.forwardRef<HTMLVideoElement>((props, ref) => {
	const sectionRef = useRef<HTMLElement>(null);
	gsap.registerPlugin(ScrollTrigger);

	const data = useStaticQuery(graphql`
		query ProjectsQuery {
			markdownRemark(frontmatter: { templateKey: { eq: "projects" } }) {
				frontmatter {
					projects {
						title
						key
						projectType
						link
						coverImage {
							childImageSharp {
								fluid(maxWidth: 916) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		}
	`);

	const projects = data.markdownRemark.frontmatter.projects;

	useEffect(() => {
		// let tween: gsap.core.Tween;
		setTimeout(() => {
			ScrollTrigger.matchMedia({
				"(max-width: 480px)": () => {
					if (null !== ref.current) {
						gsap.to(["main", "header"], {
							scrollTrigger: {
								trigger: ref.current,
								endTrigger: sectionRef!.current!,
								toggleActions: "play reverse play reset",
								start: "top top",
								end: "bottom center",
							},
							backgroundColor: "#ffffff",
							ease: "power2.out",
							duration: 0.4,
						});
					}
				},
				"(min-width: 481px)": () => {
					if (null !== ref.current) {
						gsap.to(["main", "header"], {
							scrollTrigger: {
								trigger: ref.current,
								endTrigger: sectionRef!.current!,
								toggleActions: "play reverse play reset",
								start: "top top",
								end: "bottom center",
							},
							backgroundColor: "#ffffff",
							ease: "power2.out",
							duration: 0.4,
						});
					}
				},
				// desktop
				// "(min-width: 768px)": () => {
				// 	if (null !== sectionRef.current) {
				// 		tween = gsap.to("main", {
				// 			scrollTrigger: {
				// 				trigger: sectionRef.current,
				// 				toggleActions: "play reverse play reverse",
				// 				start: "top bottom",
				// 				end: "bottom center",
				// 				markers: {
				// 					startColor: "green",
				// 					endColor: "red",
				// 					fontSize: "12px",
				// 				},
				// 			},
				// 			backgroundColor: "#ffffff",
				// 			ease: "none",
				// 		});
				// 	}
				// },

				"(min-width: 1200px)": () => {
					if (null !== ref.current) {
						gsap.to(["main", "header"], {
							scrollTrigger: {
								trigger: ref.current,
								endTrigger: sectionRef.current,
								toggleActions: "play reverse play reset",
								start: "top top",
								end: "bottom center",
								// markers: {
								// 	startColor: "green",
								// 	endColor: "red",
								// 	fontSize: "12px",
								// },
							},
							backgroundColor: "#ffffff",
							ease: "power2.out",
							duration: 0.4,
						});
					}
				},
			});
		}, 500);
		return () => {
			// tween.pause(0).kill();
			// ScrollTrigger.getAll()[0].kill();
		};
	}, [sectionRef]);

	// const toProjects = () => {
	// 	navigate("/work");
	// };

	return (
		<section className={styles.projects} ref={sectionRef}>
			<Wrap>
				<div className={styles.projectsList}>
					{projects.slice(0, 3).map((project: any) => {
						return (
							<Project
								key={project.key}
								image={project.coverImage.childImageSharp.fluid}
								title={project.title}
								subtitle={project.projectType}
								link={project.link}
								size="large"
							/>
						);
					})}
				</div>
				<Button color="light" style={styles.projectsButton} to="work">
					View all projects
				</Button>
			</Wrap>
		</section>
	);
});

export default Projects;
