import cx from "classnames";
import React, { forwardRef } from "react";
import styles from "./TextArea.module.scss";

interface Props {
	placeholder: string;
	style?: string;
	name: string;
}

type Ref = HTMLTextAreaElement;

const Input = forwardRef<Ref, Props>(({ placeholder, style, name }, ref) => (
	<textarea
		placeholder={placeholder}
		className={cx(styles.textarea, style)}
		name={name}
		ref={ref}
	/>
));

export default Input;
