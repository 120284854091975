/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/main.scss";
import React from "react";
import CustomCursor from "./src/components/CustomCursor/CustomCursor";
import CustomScroll from "./src/components/CustomScroll/CustomScroll";

// export const wrapRootElement = ({ element, props }) => (
// 	<CustomCursor {...props}>{element}</CustomCursor>
// );

export const wrapPageElement = ({ element, props }) => (
	<CustomScroll {...props}>
		<CustomCursor {...props}>{element}</CustomCursor>
	</CustomScroll>
);

// export const onInitialClientRender = () => {
// 	const documentGlobal = typeof document !== "undefined" && document;
// 	const windowGlobal = typeof window !== "undefined" && window;

// 	const TweenMax = windowGlobal.TweenMax;

// 	if (typeof TweenMax !== "undefined" && TweenMax) {
// 		documentGlobal.addEventListener("mousemove", function(e) {
// 			magnetize(".magnetize", e);
// 		});
// 	}

// 	function magnetize(el, e) {
// 		var mX = e.pageX,
// 			mY = e.pageY;
// 		const items = documentGlobal.querySelectorAll(el);

// 		[].forEach.call(items, function(item) {
// 			const customDist = item.getAttribute("dist") * 20 || 120;
// 			const centerX = item.offsetLeft + item.clientWidth / 2;
// 			const centerY = item.offsetTop + item.clientHeight / 2;

// 			var deltaX = Math.floor(centerX - mX) * -0.45;
// 			var deltaY = Math.floor(centerY - mY) * -0.45;

// 			var distance = calculateDistance(item, mX, mY);

// 			if (distance < customDist) {
// 				TweenMax.to(item, 0.3, { y: deltaY, x: deltaX });
// 				item.classList.add("magnet");
// 			} else {
// 				TweenMax.to(item, 0.45, { y: 0, x: 0 });
// 				item.classList.remove("magnet");
// 			}
// 		});
// 	}

// 	function calculateDistance(elem, mouseX, mouseY) {
// 		return Math.floor(
// 			Math.sqrt(
// 				Math.pow(mouseX - (elem.offsetLeft + elem.clientWidth / 2), 2) +
// 					Math.pow(mouseY - (elem.offsetTop + elem.clientHeight / 2), 2)
// 			)
// 		);
// 	}
// };

// const transitionDelay = 250

// export const shouldUpdateScroll = () => false

// export const onRouteUpdate = () =>
// 	window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
