import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cx from "classnames";
import { Link } from "gatsby";
import {
	gsap,
	Power2,
	TimelineLite,
	TimelineMax,
	TweenMax,
} from "gsap/dist/gsap";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import LogoImageBlack from "../../assets/images/outer-logo-black.svg";
import LogoImage from "../../assets/images/outer-logo.svg";
import CustomLink from "../CustomLink/CustomLink";
import styles from "./MobileMenu.module.scss";

interface Props {
	open: boolean;
}

const navLinks = [
	{
		link: "/work",
		text: "Work",
	},
	{
		link: "/about",
		text: "About",
	},
	{
		link: "/contact",
		text: "Contact",
	},
];

const documentGlobal = typeof document !== "undefined" ? document : undefined;

const MobileMenu = ({ open }: Props) => {
	let overlay = useRef(null);
	const refs = [];
	// let refs = useMemo(
	// 	() => Array.from({ length: navLinks.length }).map(() => createRef()),
	// 	[]
	// );

	const [menuTl] = useState(() => new TimelineMax({ paused: true }));

	useEffect(() => {
		menuTl
			.to(
				overlay,
				{
					y: 0,
					ease: Power2.easeInOut,
				},
				0.8
			)
			.staggerTo(refs, 0.5, { autoAlpha: 1, y: -20 }, 0.1);
	}, []);

	useEffect(() => {
		if (open) {
			menuTl.play();
			disableBodyScroll(documentGlobal!.querySelector("body"));
		} else {
			menuTl.reverse();
			enableBodyScroll(documentGlobal!.querySelector("body"));
		}
		// open === true ? menuTl.play() : menuTl.reverse();
	}, [open]);

	return (
		<div className={cx(styles.menu)} ref={el => (overlay = el)}>
			{navLinks.map((navLink, i) => (
				<CustomLink
					ref={el => (refs[i] = el)}
					key={navLink.link}
					to={navLink.link}
					type="mobile"
					color="black"
					style={styles.link}
					activeClass={styles.activeLink}
					partiallyActive={true}>
					{navLink.text}
				</CustomLink>
			))}
		</div>
	);
};
export default MobileMenu;
