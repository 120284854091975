import { RefObject } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { bodyScrollBar } from "../components/CustomScroll/CustomScroll";

gsap.registerPlugin(ScrollTrigger);

const documentGlobal = typeof document !== "undefined" ? document : undefined;
const windowGlobal = typeof window !== "undefined" ? window : undefined;

export const lineReveal = (ref: RefObject<HTMLDivElement>) => {
	gsap.from(ref.current, {
		scrollTrigger: {
			trigger: ref!.current!,
			endTrigger: "footer",
			toggleActions: "play none none none",
			start: "top 70%",
			end: "bottom top",
			// markers: { startColor: "green", endColor: "red", fontSize: "12px" },
			refreshPriority: 8,
			once: true,
		},
		// y: 20,
		opacity: 0,
		duration: 0.5,
	});
};

export const projectExitAnimation = ({
	node,
	e,
	exit,
	entry,
	scrollPosition,
	headerRef,
	heroRef,
}) => {
	e.preventDefault();
	windowGlobal!.scrollTo(0, scrollPosition);

	const tl = gsap.timeline({ paused: true });

	tl.to(["main", "header"], {
		backgroundColor: "#000000",
	})
		.to(
			heroRef.current,
			{
				opacity: 0,
			},
			"<"
		)
		.set(headerRef.current, {
			overflow: "visible",
		})
		.to(headerRef.current, {
			y: -(windowGlobal!.innerHeight - headerRef.current?.clientHeight!),
		});

	tl.play();
};

export const projectsEntryAnimation = ({ node }) => {
	windowGlobal!.scrollTo(0, 0);
	bodyScrollBar.scrollTo(0, 0, 0);
	gsap.from(node, {
		opacity: 0,
	});
};

// export const linkEntryAnimation = ({ node }) => {

// }

export const linkExitAnimation = ({ exit, node }) => {
	gsap.to(node, {
		opacity: 0,
	});
};

export const linkEntryAnimation = ({ exit, node }) => {
	// bodyScrollBar.scrollTo(0, 0, 0);
	windowGlobal!.scrollTo(0, 0);
	bodyScrollBar.scrollTo(0, 0, 0);
	gsap.from(node, {
		opacity: 0,
	});
};
