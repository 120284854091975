import cx from "classnames";
import React, { forwardRef, useState } from "react";
import styles from "./Input.module.scss";

interface Props {
	type: "email" | "text" | "file";
	placeholder: string;
	style?: string;
	name: string;
}

type Ref = HTMLInputElement;

const Input = forwardRef<Ref, Props>(
	({ type, placeholder, style, name }, ref) => {
		const [file, setFile] = useState(null);

		const onChangeFile = e => {
			setFile(e.target.files[0]);
		};

		switch (type) {
			case "text":
				return (
					<input
						type="text"
						placeholder={placeholder}
						className={cx(style, styles.input)}
						name={name}
						ref={ref}
					/>
				);
			case "email":
				return (
					<input
						type="text"
						placeholder={placeholder}
						className={cx(styles.input, style)}
						name={name}
						ref={ref}
					/>
				);
			case "file":
				return (
					<div className={cx(styles.file, style)}>
						<input type="file" onChange={onChangeFile} name={name} ref={ref} />
						<label htmlFor="file" className={cx(file && styles.activeFile)}>
							{file ? file.name : "Attach file"}
						</label>
					</div>
				);
			default:
				return null;
		}
	}
);

export default Input;
