import React, { useEffect, useRef } from "react";
import CustomLink from "../../components/CustomLink/CustomLink";
import FooterHeader from "../../components/FooterHeader/FooterHeader";
import Title from "../../components/Text/Text";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./Footer.module.scss";
import gsap from "gsap";

interface Props {
	showHeader: boolean;
}

const Footer = ({ showHeader }: Props) => {
	const wrapRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (showHeader) {
			// gsap.from(wrapRef.current, {
			// 	scrollTrigger: {
			// 		trigger: "footer",
			// 		endTrigger: "footer",
			// 		toggleActions: "play none none none",
			// 		start: "top bottom",
			// 		end: "bottom bottom",
			// 		refreshPriority: 3,
			// 		scrub: 0.25,
			// 	},
			// 	y: -300,
			// });
		}
	}, []);

	return (
		<footer className={styles.footer}>
			<Wrap style={styles.wrap}>
				<div className={styles.animationWrap} ref={wrapRef}>
					<FooterHeader show={showHeader} />
					<div className={styles.line} />
					<div className={styles.navBlock}>
						<div className={styles.nav}>
							<CustomLink
								to="https://www.instagram.com/outer.studio/"
								color="black"
								style={styles.link}
								type="link">
								Instagram
							</CustomLink>
							<CustomLink
								to="https://www.behance.net/outerstudio"
								color="black"
								style={styles.link}
								type="link">
								Behance
							</CustomLink>
							<CustomLink
								to="https://dribbble.com/outer"
								color="black"
								style={styles.link}
								type="link">
								Dribbble
							</CustomLink>
							<CustomLink
								to="https://www.awwwards.com/outer"
								color="black"
								style={styles.link}
								type="link">
								Awwwards
							</CustomLink>
						</div>
						<Title color="black" style={styles.outer} type="subheading">
							©outer
						</Title>
					</div>
				</div>
			</Wrap>
		</footer>
	);
};

export default Footer;
