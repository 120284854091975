import cx from "classnames";
import React, { useState, useRef, useEffect } from "react";
import MediaQuery from "react-responsive";
import Burger from "../../components/Burger/Burger";
import Logo from "../../components/Logo/Logo";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import Wrap from "../../components/Wrap/Wrap";
import Nav from "../Nav/Nav";
import styles from "./Header.module.scss";
import gsap from "gsap";
import useDocumentScrollThrottled from "../../hooks/useDocumentScrollThrottled";
import { bodyScrollBar } from "../../components/CustomScroll/CustomScroll";

const MINIMUM_SCROLL = 40;
const TIMEOUT_DELAY = 200;

interface Props {
	color: "black" | "white";
}

const Header = ({ color }: Props) => {
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLElement>(null);

	let scrollPosition = 0;

	if (typeof document !== undefined && bodyScrollBar) {
		bodyScrollBar.addListener(({ offset }) => {
			scrollPosition = offset.y;
			if (ref.current) {
				ref.current.style.top = offset.y + "px";
			}
		});
	}

	useDocumentScrollThrottled((callbackData: any) => {
		const { previousScrollTop, currentScrollTop } = callbackData;
		const isScrolledDown = previousScrollTop < currentScrollTop;
		const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

		if (ref.current) {
			setTimeout(() => {
				if (isScrolledDown && isMinimumScrolled && ref.current?.clientHeight) {
					gsap.to(ref.current, {
						y: -ref!.current!.clientHeight,
					});
				} else {
					gsap.to(ref.current, {
						y: 0,
					});
				}
			}, TIMEOUT_DELAY);
		}
	});

	// useEffect(() => {
	// 	gsap.from(ref.current, {
	// 		opacity: 0,
	// 		delay: 0.3,
	// 	});
	// }, []);

	return (
		<header className={cx(styles.header, styles[color])} ref={ref}>
			<Wrap style={styles.wrap}>
				<Logo />
				<MediaQuery maxDeviceWidth="768px">
					<Burger setOpen={setOpen} open={open} />
					<MobileMenu open={open} />
				</MediaQuery>
				<MediaQuery minDeviceWidth="769px">
					<Nav />
				</MediaQuery>
			</Wrap>
		</header>
	);
};

export default Header;
