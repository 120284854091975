import React from "react";
import helloWorldCover from "../../assets/projects/helloworld/cover.jpg";
import totecCover from "../../assets/projects/totec/cover.jpg";
// import Button from "../../components/Button/Button";
import Project from "../../components/Project/Project";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./ProjectsLong.module.scss";
import { useStaticQuery, graphql } from "gatsby";

const Projects = () => {
	const data = useStaticQuery(graphql`
		query ProjectsLongQuery {
			markdownRemark(frontmatter: { templateKey: { eq: "projects" } }) {
				frontmatter {
					projects {
						title
						key
						projectType
						link
						coverImage {
							childImageSharp {
								fluid(maxWidth: 916) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	`);

	const projects = data.markdownRemark.frontmatter.projects;

	return (
		<section className={styles.projects}>
			<Wrap style={styles.projectsWrap}>
				{projects.map((project: any) => {
					return (
						<Project
							key={project.key}
							image={project.coverImage.childImageSharp.fluid}
							title={project.title}
							subtitle={project.projectType}
							link={project.link}
							size="large"
						/>
					);
				})}
				{/* <Project
				image={helloWorldCover}
				title="Hello World Agency Portfolio"
				subtitle="Website"
				link="/hello-world"
				size="large"
			/>
			<Project
				image={totecCover}
				title="Totec Boutique Hotel"
				subtitle="Branding & Website"
				link="/totec"
				size="large"
			/> */}
			</Wrap>
		</section>
	);
};

export default Projects;
