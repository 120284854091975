import cx from "classnames";
import { Link } from "gatsby";
import React, { useEffect, useRef } from "react";
import CustomLink from "../CustomLink/CustomLink";
import styles from "./Project.module.scss";
import Img, { FluidObject } from "gatsby-image";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TransitionLink from "gatsby-plugin-transition-link";
import { linkEntryAnimation, linkExitAnimation } from "../../animations/other";

interface Props {
	image: FluidObject | FluidObject[] | undefined;
	title: string;
	subtitle: string;
	link: string;
	size: "large" | "medium" | "small";
}

const Project = ({ image, title, subtitle, link, size }: Props) => {
	gsap.registerPlugin(ScrollTrigger);

	const titleRef = useRef<HTMLAnchorElement>(null);
	const subtitleRef = useRef<HTMLAnchorElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: titleRef!.current!,
				endTrigger: "footer",
				toggleActions: "play none none none",
				start: "top 90%",
				end: "bottom top",
				// markers: { startColor: "green", endColor: "red", fontSize: "12px" },
				refreshPriority: 0,
				once: true,
			},
		});

		tl.from(titleRef.current, {
			y: 45,
			autoAlpha: 0,
			duration: 1,
			ease: "power4.out",
		}).from(
			subtitleRef.current,
			{
				y: 45,
				autoAlpha: 0,
				duration: 1,
				ease: "power4.out",
				delay: 0.25,
			},
			"<"
		);
	}, []);

	return (
		<div className={cx(styles.project, styles[size])} ref={containerRef}>
			{/* <Link to={link} className={cx(styles.imageLink)}> */}
			<TransitionLink
				to={link}
				className={styles.imageLink}
				exit={{
					trigger: linkExitAnimation,
					length: 1,
				}}
				entry={{
					trigger: linkEntryAnimation,
					delay: 0.6,
				}}>
				<Img fluid={image} className={styles.image} />
			</TransitionLink>
			{/* <img src={image} className={styles.image} /> */}
			{/* </Link> */}
			<div className={styles.lineWrap}>
				<CustomLink
					type="link"
					to={link}
					color="white"
					style={styles.titleLink}
					ref={titleRef}>
					{title}
				</CustomLink>
			</div>
			<div className={styles.lineWrap}>
				<CustomLink
					type="link"
					to={link}
					color="grey"
					style={styles.subtitleLink}
					ref={subtitleRef}>
					{subtitle}
				</CustomLink>
			</div>
		</div>
	);
};
export default Project;
