import cx from "classnames";
import React from "react";
import styles from "./Button.module.scss";
import TransitionLink from "gatsby-plugin-transition-link";
import { linkEntryAnimation, linkExitAnimation } from "../../animations/other";

interface Props {
	children: string;
	onClick?: () => void;
	color: "dark" | "light";
	style?: string;
	to?: string;
}

const Button = ({ children, onClick, color = "light", style, to }: Props) =>
	to ? (
		<TransitionLink
			to={to}
			className={cx(styles.button, styles[color], style)}
			exit={{
				trigger: linkExitAnimation,
				length: 1,
			}}
			entry={{
				trigger: linkEntryAnimation,
				delay: 0.6,
			}}
			data-attribute="button-hover">
			{children}
		</TransitionLink>
	) : (
		<button
			className={cx(styles.button, styles[color], style)}
			onClick={onClick}>
			{children}
		</button>
	);

export default Button;
