import React from "react";
import Wrap from "../../components/Wrap/Wrap";
import Text from "../../components/Text/Text";
import styles from "./ProjectHeader.module.scss";
import Img, { FluidObject } from "gatsby-image";

interface Props {
	title: string;
	projectType: string;
	source: {
		publicURL: string;
	};
}

const ProjectHeader = React.forwardRef(
	({ title, projectType, source }: Props, ref) => {
		return (
			<section
				className={styles.projectHeader}
				ref={ref as React.MutableRefObject<HTMLElement>}>
				<Wrap style={styles.headerWrap}>
					<Text color="white" type="subheading" style={styles.next}>
						Next Project
					</Text>
					<div className={styles.textWrap}>
						<Text color="white" type="headline">
							{title}
						</Text>
						<Text color="grey" type="headline">
							{projectType}
						</Text>
					</div>

					{/* <Img
						fluid={source.childImageSharp.fluid}
						className={styles.heroCover}
						// ref={coverRef}
					/> */}

					<img src={source.publicURL} className={styles.heroCover} />
				</Wrap>
			</section>
		);
	}
);

export default ProjectHeader;
