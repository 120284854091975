import { useMediaQuery } from "react-responsive";

export const Mobile = ({ children }: any) => {
	// const isMobile = useMediaQuery({ maxWidth: 479 });
	return isMobile() ? children : null;
};

export const Tablet = ({ children }: any) => {
	// const isTablet = useMediaQuery({ minWidth: 480 });
	return isTablet() ? children : null;
};

export const SmallDesktop = ({ children }: any) => {
	return isSmallDesktop() ? children : null;
};

export const isMobile = () => {
	return useMediaQuery({ maxWidth: 479 });
};

export const isTablet = () => {
	return useMediaQuery({ minWidth: 480 });
};

export const isSmallDesktop = () => {
	return useMediaQuery({ minWidth: 769 });
};

// export const isMobile = useMediaQuery({ maxDeviceWidth: 480 });
