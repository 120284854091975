import { useEffect, useState } from "react";
import { throttle } from "lodash";
import { bodyScrollBar } from "../components/CustomScroll/CustomScroll";

interface CallbackArgs {
	previousScrollTop: number;
	currentScrollTop: number;
}

interface ListenerArgs {
	offset: {
		x: number;
		y: number;
	};
}

function useDocumentScrollThrottled(
	callback: ({ previousScrollTop, currentScrollTop }: CallbackArgs) => void
) {
	const [, setScrollPosition] = useState(0);
	let previousScrollTop = 0;

	function handleDocumentScroll({ offset }: ListenerArgs) {
		const currentScrollTop = offset.y;
		// const { scrollTop: currentScrollTop } =
		// 	document.documentElement || document.body;

		setScrollPosition(previousPosition => {
			previousScrollTop = previousPosition;
			return currentScrollTop;
		});

		callback({ previousScrollTop, currentScrollTop });
	}

	const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250);

	useEffect(() => {
		if (typeof document !== undefined && bodyScrollBar) {
			bodyScrollBar.addListener(handleDocumentScrollThrottled);
		}

		// return () =>
		// window.removeEventListener("scroll", handleDocumentScrollThrottled);
	}, [bodyScrollBar]);
}

export default useDocumentScrollThrottled;
