import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import Title from "../Text/Text";
import { addToTimeline } from "./animation";
import styles from "./ChangingText.module.scss";
import cx from "classnames";

interface Props {
	list: string[];
	className: string;
	color: "grey" | "white" | "black";
}

const ChangingText = ({ list, color, className }: Props) => {
	const refs: HTMLHeadingElement[] = [];
	const listRef = useRef<HTMLSpanElement>(null);

	const [listTl] = useState(() =>
		gsap.timeline({
			delay: 2,
			paused: true,
			repeat: -1,
		})
	);

	useEffect(() => {
		addToTimeline({ list, listTl, refs });
		listTl.play();
	}, [list]);

	return (
		<span className={cx(styles.frame, className)} ref={listRef}>
			{list.map((text, i) => (
				<Title
					key={text}
					type="headline"
					style={styles.text}
					color={color}
					ref={(node: HTMLHeadingElement) => (refs[i] = node)}>
					{text}
				</Title>
			))}
		</span>
	);
};

export default ChangingText;
