// import cx from "classnames";
import React, { useEffect, useRef } from "react";
import CustomLink from "../CustomLink/CustomLink";
import Text from "../Text/Text";
import styles from "./ServiceBlock.module.scss";
import { linesRevealScroll } from "../../animations/text";
import gsap from "gsap";
import SplitText from "../../lib/SplitText";

interface Props {
	index: string;
	title: string;
	description: string;
}

const ServiceBlock = ({ index, title, description }: Props) => {
	const headlineRef = useRef<HTMLHeadingElement>(null);
	const indexRef = useRef<HTMLHeadingElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const descriptionRef = useRef<HTMLHeadingElement>(null);
	const linkRef = useRef<HTMLAnchorElement>(null);
	const lineRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: indexRef!.current!,
				endTrigger: "footer",
				toggleActions: "play none none none",
				start: "top 70%",
				end: "bottom top",
				refreshPriority: 3,
				once: true,
			},
		});

		const splitIndex = new SplitText(indexRef.current, {
			type: "lines",
		});

		// tslint:disable-next-line:no-unused-expression
		new SplitText(indexRef.current, {
			type: "lines",
			linesClass: styles.lineWrap,
		});

		const splitHeadline = new SplitText(headlineRef.current, {
			type: "lines",
		});

		// tslint:disable-next-line:no-unused-expression
		new SplitText(headlineRef.current, {
			type: "lines",
			linesClass: styles.lineWrap,
		});

		const splitDescription = new SplitText(descriptionRef.current, {
			type: "lines",
		});

		// tslint:disable-next-line:no-unused-expression
		new SplitText(descriptionRef.current, {
			type: "lines",
			linesClass: styles.lineWrap,
		});

		const splitLink = new SplitText(linkRef.current, {
			type: "lines",
		});

		// tslint:disable-next-line:no-unused-expression
		new SplitText(linkRef.current, {
			type: "lines",
			linesClass: styles.lineWrap,
		});

		tl.from(splitIndex.lines, {
			autoAlpha: 0,
			y: 45,
			stagger: 0.25,
			duration: 1,
			ease: "power4.out",
		})
			.from(
				splitHeadline.lines,
				{
					y: 45,
					autoAlpha: 0,
					delay: 0.25,
					stagger: 0.25,
					duration: 1,
					ease: "power4.out",
				},
				"<"
			)
			.from(
				splitDescription.lines,
				{
					y: 45,
					autoAlpha: 0,
					delay: 0.25,
					stagger: 0.06,
					duration: 1,
					ease: "power4.out",
				},
				"<"
			)
			.from(
				splitLink.lines,
				{
					y: 45,
					autoAlpha: 0,
					delay: 0.25,
					stagger: 0.25,
					duration: 1,
					ease: "power4.out",
				},
				"<"
			);
		// linesRevealScroll(headlineRef);
	}, []);

	return (
		<div ref={containerRef}>
			<div className={styles.container}>
				<Text
					type="subheading"
					color="grey"
					style={styles.index}
					ref={indexRef}>
					{index}
				</Text>
				<Text
					type="headline"
					color="white"
					style={styles.title}
					ref={headlineRef}>
					{title}
				</Text>
				<div className={styles.descriptionContainer}>
					<Text
						type="paragraph"
						color="grey"
						style={styles.description}
						ref={descriptionRef}>
						{description}
					</Text>
					<CustomLink
						to="/work"
						type="smallLink"
						color="grey"
						style={styles.link}
						ref={linkRef}>
						VIEW PROJECTS
					</CustomLink>
				</div>
			</div>

			<div className={styles.line} ref={lineRef} />
		</div>
	);
};

export default ServiceBlock;
