import React, { Fragment } from "react";
import CustomScroll from "../components/CustomScroll/CustomScroll";
import Clients from "../containers/Clients/Clients";
import Footer from "../containers/Footer/Footer";
import Header from "../containers/Header/Header";
import ProjectsLong from "../containers/ProjectsLong/ProjectsLong";

const WorksPage = () => (
	<Fragment>
		<Header color="black" />
		<ProjectsLong />
		<Clients />
		<Footer showHeader={true} />
	</Fragment>
);

export default WorksPage;
