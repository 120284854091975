import React, { useState } from "react";
import ChangingText from "../../components/ChangingText/ChangingText";
import CustomLink from "../../components/CustomLink/CustomLink";
import Form from "../../components/Form/Form";
import Text from "../../components/Text/Text";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./Contacts.module.scss";

const list = ["development?", "branding?", "web design?", "value?"];

const Contacts = () => {
	const [formSuccess, setFormSuccess] = useState(false);

	return (
		<section className={styles.contactSection}>
			<Wrap style={styles.contactsWrap}>
				<div className={styles.textWrap}>
					{formSuccess ? (
						<Text type="headline" color="black" style={styles.contactHeading}>
							Your message was successfully sent. <br />{" "}
							<span>We’ll get back to you soon.</span>
						</Text>
					) : (
						<Text type="headline" color="black" style={styles.contactHeading}>
							Are you looking for{" "}
							<ChangingText
								color="black"
								list={list}
								className={styles.changingText}
							/>
							{/* <span>value</span>? */}
							<br />
							Let’s talk.
						</Text>
					)}

					<div className={styles.contactInformation}>
						<CustomLink
							color="black"
							type="smallLink"
							to="mailto:hello@outer.studio"
							style={styles.contact}>
							hello@outer.studio
						</CustomLink>
						<CustomLink
							color="black"
							type="smallLink"
							to="tel:+370 (608) 00 635"
							style={styles.contact}>
							+370 (608) 00 635
						</CustomLink>
					</div>
				</div>
				{!formSuccess && <Form onSuccess={setFormSuccess} />}
			</Wrap>
		</section>
	);
};

export default Contacts;
