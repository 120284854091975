import alfa from "../../assets/clients/alfa.svg";
import alna from "../../assets/clients/alna.svg";
import clinic from "../../assets/clients/clinic-212.svg";
import corehr from "../../assets/clients/corehr.svg";
import drivitty from "../../assets/clients/drivitty.svg";
import dserve from "../../assets/clients/dserve.svg";
import fincor from "../../assets/clients/fincor.svg";
import nightwatch from "../../assets/clients/nightwatch.svg";
import reneza from "../../assets/clients/reneza.svg";
import tmd from "../../assets/clients/tmd.svg";
import vilniusConnect from "../../assets/clients/vilnius-connect.svg";
import wePower from "../../assets/clients/we-power.svg";
import wideWings from "../../assets/clients/wide-wings.svg";
import wooshii from "../../assets/clients/wooshii.svg";

export default [
	{
		image: tmd,
		key: "tmd",
	},
	{
		image: alfa,
		key: "alfa",
	},
	{
		image: nightwatch,
		key: "nightwatch",
	},
	{
		image: clinic,
		key: "clinic",
	},
	{
		image: corehr,
		key: "corehr",
	},
	{
		image: drivitty,
		key: "drivitty",
	},
	{
		image: dserve,
		key: "dserve",
	},
	{
		image: vilniusConnect,
		key: "vilniusConnect",
	},
	{
		image: fincor,
		key: "fincor",
	},
	{
		image: reneza,
		key: "reneza",
	},
	{
		image: wideWings,
		key: "wideWings",
	},
	{
		image: wePower,
		key: "wePower",
	},
	{
		image: alna,
		key: "alna",
	},
	{
		image: wooshii,
		key: "wooshii",
	},
];
