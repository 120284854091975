import cx from "classnames";
import React from "react";
import CustomLink from "../../components/CustomLink/CustomLink";
import styles from "./Nav.module.scss";

const Nav = () => (
	<nav>
		<CustomLink
			to="/work/"
			color="white"
			style={cx(styles.link)}
			type="link"
			dataAttribute="nav-hover"
			activeClass={styles.activeLink}>
			Work
		</CustomLink>
		<CustomLink
			to="/about/"
			color="white"
			style={cx(styles.link)}
			type="link"
			dataAttribute="nav-hover"
			activeClass={styles.activeLink}>
			About
		</CustomLink>
		<CustomLink
			to="/contact/"
			color="white"
			style={cx(styles.link)}
			type="link"
			dataAttribute="nav-hover"
			activeClass={styles.activeLink}>
			Contact
		</CustomLink>
	</nav>
);

export default Nav;
