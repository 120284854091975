import React from "react";
import LogoImage from "../../assets/images/outer-logo.svg";
import styles from "./Logo.module.scss";
import TransitionLink from "gatsby-plugin-transition-link";
import { linkEntryAnimation, linkExitAnimation } from "../../animations/other";

const Logo = () => {
	return (
		<TransitionLink
			to="/"
			className={styles.logoContainer}
			exit={{
				trigger: linkExitAnimation,
				length: 1,
			}}
			entry={{
				trigger: linkEntryAnimation,
				delay: 0.6,
			}}>
			<img src={LogoImage} className={styles.logo} />
		</TransitionLink>
	);
};

export default Logo;
