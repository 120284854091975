import cx from "classnames";
import { Link } from "gatsby";
import React from "react";
import styles from "./CustomLink.module.scss";
import TransitionLink from "gatsby-plugin-transition-link";
import gsap from "gsap";
import { linkEntryAnimation, linkExitAnimation } from "../../animations/other";

interface Props {
	to: string;
	children: string;
	color: "black" | "white" | "grey";
	type: "link" | "mobile" | "smallLink";
	activeClass?: string;
	style?: string;
	dataAttribute?: string;
	partiallyActive?: boolean;
}

const CustomLink = React.forwardRef(
	(
		{
			to,
			children,
			style,
			color,
			type = "link",
			dataAttribute,
			activeClass,
			partiallyActive,
		}: Props,
		ref
	) => {
		const internal = /^\/(?!\/)/.test(to);

		return internal ? (
			<TransitionLink
				to={to}
				className={cx(style, styles[color], styles[type])}
				activeClassName={activeClass}
				ref={ref}
				exit={{
					trigger: linkExitAnimation,
					length: 1,
				}}
				entry={{
					trigger: linkEntryAnimation,
					delay: 0.6,
				}}
				data-attribute={dataAttribute}
				partiallyActive={partiallyActive}>
				{children}
			</TransitionLink>
		) : (
			<a
				href={to}
				className={cx(style, styles[color], styles[type])}
				ref={ref as React.MutableRefObject<HTMLAnchorElement>}
				target="_blank"
				data-attribute={dataAttribute}>
				{children}
			</a>
		);
	}
);

export default CustomLink;
