import React from "react";
import styles from "./ClientLogo.module.scss";

interface Props {
	image: string;
}

const ClientLogo = ({ image }: Props) => (
	<img src={image} className={styles.logo} />
);

export default ClientLogo;
