import React, { useEffect, useRef } from "react";
import ServiceBlock from "../../components/ServiceBlock/ServiceBlock";
import Text from "../../components/Text/Text";
import Wrap from "../../components/Wrap/Wrap";
import styles from "./Services.module.scss";
import data from "./ServicesData";
import { linesReveal, linesRevealScroll } from "../../animations/text";
import gsap from "gsap";
import { lineReveal } from "../../animations/other";

const Services = () => {
	const headingRef = useRef<HTMLHeadingElement>(null);
	const lineRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		linesRevealScroll(headingRef);

		gsap.to(lineRef.current, {
			scrollTrigger: {
				trigger: lineRef.current!,
				endTrigger: "footer",
				toggleActions: "play none none none",
				start: "top 90%",
				end: "bottom top",
				refreshPriority: 0,
				once: true,
			},
			opacity: 1,
			duration: 0.3,
			// delay: 0.5,
		});
	}, [headingRef.current]);

	const renderServiceBlocks = data.services.map(service => (
		<ServiceBlock
			key={service.key}
			index={service.key}
			title={service.title}
			description={service.description}
		/>
	));

	return (
		<section className={styles.services}>
			<Wrap>
				<div className={styles.descriptionContainer}>
					<Text
						type="headline"
						color="grey"
						style={styles.description}
						ref={headingRef}>
						We should live in a world where any product or service has an easy
						to use experience on all platforms. Our goal is to{" "}
						<span>bring that to reality.</span>
					</Text>
				</div>
				<div className={styles.line} ref={lineRef} />
				<div>{renderServiceBlocks}</div>
			</Wrap>
		</section>
	);
};

export default Services;
