import React, { useRef } from "react";
import Clients from "../containers/Clients/Clients";
import Footer from "../containers/Footer/Footer";
import Header from "../containers/Header/Header";
import Hero from "../containers/Hero/Hero";
import Projects from "../containers/Projects/Projects";
import Services from "../containers/Services/Services";

const IndexPage = () => {
	const showreelRef = useRef<HTMLVideoElement>(null);

	return (
		<main style={{ backgroundColor: "#000000" }}>
			<Header color="black" />
			<Hero ref={showreelRef} />
			<Projects ref={showreelRef} />
			<Services />
			<Clients />
			<Footer showHeader={true} />
		</main>
	);
};

export default IndexPage;
