import cx from "classnames";
import React, { Fragment, useState, useEffect, useRef } from "react";
import styles from "./CustomCursor.module.scss";
import gsap from "gsap";

interface Props {
	children: React.ReactNode;
}

const documentGlobal = typeof document !== "undefined" ? document : undefined;

const CustomCursor = ({ children }: Props) => {
	const spanRef = useRef<HTMLSpanElement>(null);
	const [hover, setHover] = useState<boolean>(false);
	const [hoverBig, setHoverBig] = useState<boolean>(false);
	const [hoverSmall, setHoverSmall] = useState<boolean>(false);

	useEffect(() => {
		// gsap.set(spanRef.current, {
		// 	marginLeft: "-0.5rem",
		// 	marginTop: "-0.5rem",
		// });

		documentGlobal!.addEventListener("mousemove", e => {
			const hoveredElement = documentGlobal!.elementFromPoint(
				e.clientX,
				e.clientY
			) as HTMLElement;

			if (
				hoveredElement &&
				hoveredElement.dataset &&
				hoveredElement.dataset.attribute === "nav-hover"
			) {
				const viewportOffset = hoveredElement.getBoundingClientRect();
				const left = viewportOffset.left - 13;
				const height = hoveredElement.offsetHeight;
				const top = viewportOffset.top + height / 2;
				setHoverSmall(true);

				gsap.to(spanRef.current, {
					duration: 0.2,
					x: left,
					y: top,
					ease: "power2.out",
				});
			} else {
				setHoverSmall(false);

				if (
					hoveredElement?.tagName === "A" ||
					hoveredElement?.parentNode?.nodeName === "A"
				) {
					setHover(true);
				} else {
					setHover(false);
				}

				// if (
				// 	hoveredElement?.tagName === "BUTTON" ||
				// 	hoveredElement?.parentNode?.nodeName === "BUTTON"
				// ) {
				// 	setHoverBig(true);
				// } else {
				// 	setHoverBig(false);
				// }

				if (
					(hoveredElement &&
						hoveredElement.dataset &&
						hoveredElement.dataset.attribute === "button-hover") ||
					hoveredElement?.tagName === "BUTTON" ||
					hoveredElement?.parentNode?.nodeName === "BUTTON"
				) {
					setHoverBig(true);
				} else {
					setHoverBig(false);
				}

				gsap.to(spanRef.current, {
					duration: 0.2,
					x: e.clientX,
					y: e.clientY,
					// xPercent: "50%",
					// yPercent: "50%",
					ease: "power2.out",
				});
			}

			// if (hoveredElement.dataset.attribute === "nav-hover") {
			// 	const viewportOffset = hoveredElement.getBoundingClientRect();
			// 	const bottom = viewportOffset.bottom + 11;
			// 	// const width = hoveredElement.offsetWidth;
			// 	// const left = viewportOffset.left + width / 2;
			// 	gsap.to(spanRef.current, {
			// 		duration: 0.2,
			// 		// x: left,
			// 		y: bottom,
			// 		ease: "power2.out",
			// 	});
			// } else {
			// 	gsap.to(spanRef.current, {
			// 		duration: 0.2,
			// 		x: e.clientX,
			// 		y: e.clientY,
			// 		ease: "power2.out",
			// 	});
			// }

			// else {
			// 	if (
			// 		hoveredElement?.tagName === "A" ||
			// 		hoveredElement?.parentNode?.nodeName === "A"
			// 	) {
			// 		setHover(true);
			// 	} else {
			// 		setHover(false);
			// 	}

			// 	if (
			// 		hoveredElement?.tagName === "BUTTON" ||
			// 		hoveredElement?.parentNode?.nodeName === "BUTTON"
			// 	) {
			// 		setHoverBig(true);
			// 	} else {
			// 		setHoverBig(false);
			// 	}
			// 	gsap.to(spanRef.current, {
			// 		duration: 0.2,
			// 		x: e.clientX,
			// 		y: e.clientY,
			// 		ease: "power2.out",
			// 	});
			// }
		});
	}, []);

	return (
		<Fragment>
			<span
				className={cx(styles.cursorPoint, {
					[styles.activeBig]: hoverBig,
					[styles.active]: hover,
					[styles.activeSmall]: hoverSmall,
				})}
				id="custom-cursor"
				ref={spanRef}
			/>
			{children}
		</Fragment>
	);
};

export default CustomCursor;
